import Button from "@mui/material/Button";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import { useAppContext } from "../../../../../utils/ContextProvider.jsx";
import { createEvent } from "../../../../../utils/CreateEvent/index.js";

export default function PartnerDealCard ({ partnerDeal = null, shoe = null, colorway = null, recommendationId = null }) {

    var { trackEvent } = useAppContext()

    const handleClick = () => {
        trackEvent('View partner deal', {partner: partnerDeal?.partner.name, shoeName: `${shoe?.brand.name} ${shoe?.model} ${shoe?.version}`})
        createEvent({
            action: 'view_partner_deal',
            category: 'interaction',
            data: {
                partner: partnerDeal?.partner.name,
                shoeId: shoe?._id,
                colorwayId: colorway?._id,
                recommendationId: recommendationId,
                partnerDeal: partnerDeal,
                price: partnerDeal?.price,
                specialPrice: partnerDeal?.specialPrice ? partnerDeal?.specialPrice : null
            }
        })
    }

    return (
        <div className="row">
            <div className="col text-center py-4 px-1" style={{border: `1px solid ${partnerDeal?.partner.primary}`, borderRadius: '1rem'}}>

                <div className="row">
                    <div className="col-7 mx-auto">
                        <img className="img img-fluid" src={`/logos/partners/${partnerDeal?.partner.slug}.svg`} alt="" />      
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        {partnerDeal.specialPrice ?
                        <h1 className="my-0 px-0" style={{color: partnerDeal?.partner.primary, fontSize: '2rem'}}>${partnerDeal?.specialPrice.toFixed(2)}  <span style={{color: '#687393', fontSize: '0.9rem', textDecoration: 'line-through'}}>${partnerDeal?.price.toFixed(2)}</span></h1>
                        : 
                        null
                        }
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <p><DoneIcon /> Offer in this color and your required width</p>
                        {partnerDeal.partner.offers.map((offer) => {
                            return (
                                <p><DoneIcon /> {offer}</p>
                            )
                        })}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <Button
                            variant="contained"
                            endIcon={<ArrowRightAltIcon/>}
                            size='large'
                            style={{background: `${partnerDeal?.partner.primary}`}}
                            href={`${partnerDeal?.partner.baseUrl}${partnerDeal?.urlKey}${partnerDeal?.partner.referralUrlString}`}
                            target="_blank"
                            onClick={handleClick}
                            >
                            View offer
                        </Button>
                    </div>
                </div>

            </div>  
        </div>
    )
}