import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import  Divider  from "@mui/material/Divider"
import  Button  from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography  from "@mui/material/Typography";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import { FormGroup, FormControlLabel, Checkbox, MenuItem, FormControl, InputLabel, Select, RadioGroup } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import Badge from "@mui/material/Badge";
import Radio from "@mui/material/Radio"
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { createEvent } from "../../../../utils/CreateEvent/index.js";

var checkFilters = (updatedFilters) => {
    if (updatedFilters.offers || updatedFilters.shoeShape != "" || updatedFilters.price !== 550 || updatedFilters.matchScore !== 0 || (updatedFilters.brands && updatedFilters.brands.length > 0) || updatedFilters.width != ""  || updatedFilters.rocker != ""  || updatedFilters.grip != "" || updatedFilters.carbonPlate != "" || updatedFilters.cushion != "" || updatedFilters.stability != "" ) {
        return true
    } else {
        return false
    }
}


export default function FilterPanel({ shoes, setShoes, recommendation, setPage, partnerDeals = null, filters = null, setFilters = null, filtersActive = null, setFiltersActive = null, firstShoe = null, setFirstShoe = null}) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, sessionUser } = useAppContext()

    const [expanded, setExpanded] = useState(false);

    const [allBrands, setAllBrands] = useState([])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        var url = `${requestUrl}/api/brands`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllBrands(res.data.allBrands)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
        })
        setFiltersActive(checkFilters(filters))
    }, [])

    const clearFilters = () => {
        setFilters({
            width: '',
            stability: '',
            heelToeDrop: { low: 0, high: 16 }, // Assuming max heel-toe drop is 16mm
            cushion: '',
            weight: { low: 0, high: 500 },
            grip: '',
            rockPlate: '',
            carbonPlate: '',
            shoeShape: '',
            rocker: '',
            cushionType: '',
            brands: [],
            price: 550,
            matchScore: 0,
            offers: false,
            currentShoes: false
          })
        setShoes(recommendation.shoes)
        setFiltersActive(false)
        setPage(1)
    }
    

    const handleFilterChange = (filterKey, value) => {

        createEvent({
            action: 'filter_shoes',
            category: 'interaction',
            data: {
                filterKey: filterKey,
                value: value,
                page: 'recommendations'
            }
        })
        // Handle brand filter logic
        if (filterKey === 'brands') {
            const brandArray = filters.brands.slice(); // Create a copy of the brand array
            if (brandArray.includes(value)) {
                brandArray.splice(brandArray.indexOf(value), 1);
            } else {
                brandArray.push(value);
            }
            value = brandArray;
        }
    
        // Update the filters
        const updatedFilters = {
            ...filters,
            [filterKey]: value,
        };
        setFilters(updatedFilters);
    
        // Filtering the shoes based on updated filters
        const filteredShoes = recommendation.shoes.filter((shoe) => {

            let match = true;  // Assume the shoe matches all filters by default
    
            // Apply each filter conditionally
            if (updatedFilters.stability) {
                match = match && shoe.shoe.stability.includes(updatedFilters.stability);
            }
    
            if (updatedFilters.cushion) {
                match = match && shoe.shoe.cushion === updatedFilters.cushion;
            }
    
            if (updatedFilters.grip) {
                match = match && shoe.shoe.grip === updatedFilters.grip;
            }
    
            if (updatedFilters.carbonPlate) {
                match = match && shoe.shoe.carbonPlate === updatedFilters.carbonPlate;
            }
    
            if (updatedFilters.rocker) {
                match = match && shoe.shoe.rocker === updatedFilters.rocker;
            }
    
            if (updatedFilters.width) {
                match = match && (shoe.shoe.shoeShape === 'normal' ? shoe.shoe.width.includes(updatedFilters.width) : true);
            }
    
            if (updatedFilters.brands.length > 0) {
                match = match && updatedFilters.brands.includes(shoe.shoe.brand.name);
            }

            if (updatedFilters.matchScore) {
                match = match && (shoe.score / shoe.maxScore)*100 >= updatedFilters.matchScore
            }
    
            if (updatedFilters.price) {
                const validDeal = partnerDeals?.find(deal =>
                    deal.shoe.toString() === shoe.shoe._id.toString() &&
                    (shoe.shoe.shoeShape !== 'foot shaped' ? recommendation.requirements.width.traits.includes(deal.width) : deal) &&
                    (sessionUser.location ? deal.partner.countries.includes(sessionUser.location.country) : true) &&
                    deal.specialPrice
                );
    
                // Check specialPrice or default to MSRP
                const priceToCompare = validDeal ? parseFloat(validDeal.specialPrice) : parseFloat(shoe.shoe.msrp);
                match = match && priceToCompare <= updatedFilters.price;
            }
    
            if (updatedFilters.shoeShape) {
                match = match && shoe.shoe.shoeShape === updatedFilters.shoeShape;
            }
    
            if (updatedFilters.offers) {
                match = match && partnerDeals?.some(deal =>
                    deal.shoe.toString() === shoe.shoe._id.toString() &&
                    (shoe.shoe.shoeShape !== 'foot shaped' ? 
                        recommendation.requirements.width.traits.includes(deal.width) : 
                        deal) &&
                    (sessionUser.location ? deal.partner.countries.includes(sessionUser.location.country) : true)
                );
            } 
    
            return match;
        });
        setFiltersActive(checkFilters(updatedFilters))
        setFirstShoe(filteredShoes[0])
        setShoes(filteredShoes);
        setPage(1);
    };



    return (
        <Badge color="error" badgeContent=" " invisible={!filtersActive} variant="standard" sx={{display: 'inline'}}>
            <div className="row">
                

                
                    <div className="col filters-column py-3 px-3 card">

                        <div className="row mb-4 align-items-center">
                            <div className="col mx-2">
                                <h2 className="my-0">Filter shoes</h2>
                            </div>
                            {filtersActive ?
                            <div className="col-auto py-2 mx-2">
                                <a onClick={clearFilters}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear</p></a>
                            </div>
                            : null} 
                        </div>


                        {/* Shoe filters */}
                <div className="row mt-2">                  
                    <div className="col">
                        <Accordion defaultExpanded expanded={expanded === 'shoepanel'} onChange={handleChange('shoepanel')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="shoepanelbh-content"
                                id="shoepanelbh-header"
                            >
                                <div className="row align-items-center"> 
                                    <div className="col-auto">
                                        <FeedOutlinedIcon />
                                    </div>
                                    <div className="col-auto px-1">
                                        Shoe
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <div className="row my-2">
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Cushion</InputLabel>
                                        <Select
                                        label='cushion'
                                        value={filters.cushion}
                                        onChange={(e) => handleFilterChange('cushion', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="max">Max</MenuItem>
                                        <MenuItem value="high">High</MenuItem>
                                        <MenuItem value="medium">Medium</MenuItem>
                                        <MenuItem value="low">Low</MenuItem>
                                        <MenuItem value="min">Min</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row my-2">

                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Width</InputLabel>
                                        <Select
                                        label='width'
                                        value={filters.width}
                                        onChange={(e) => handleFilterChange('width', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="narrow">Narrow</MenuItem>
                                        <MenuItem value="normal">Normal</MenuItem>
                                        <MenuItem value="wide">Wide</MenuItem>
                                        <MenuItem value="extra-wide">Extra-wide</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Stability</InputLabel>
                                        <Select
                                        label='stability'
                                        value={filters.stability}
                                        onChange={(e) => handleFilterChange('stability', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="high">High</MenuItem>
                                        <MenuItem value="light">Light</MenuItem>
                                        <MenuItem value="neutral">Neutral</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Grip */}
                            <div className="my-1">
                                {recommendation?.preferences?.shoeSurface == 'trail' ?
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Grip</InputLabel>
                                        <Select
                                        label='grip'
                                        value={filters.grip}
                                        onChange={(e) => handleFilterChange('grip', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="high">High</MenuItem>
                                        <MenuItem value="medium">Medium</MenuItem>
                                        <MenuItem value="low">Low</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                : null }
                            </div>
                            {/* carbon plate */}
                            <div className="row my-2">
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Carbon plate</InputLabel>
                                        <Select
                                        label='carbon plate'
                                        value={filters.carbonPlate}
                                        onChange={(e) => handleFilterChange('carbonPlate', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="TRUE">Yes</MenuItem>
                                        <MenuItem value="FALSE">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Rocker */}
                            <div className="row my-2">
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Rocker</InputLabel>
                                        <Select
                                        label='rocker'
                                        value={filters.rocker}
                                        onChange={(e) => handleFilterChange('rocker', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="max">Max</MenuItem>
                                        <MenuItem value="high">High</MenuItem>
                                        <MenuItem value="medium">Medium</MenuItem>
                                        <MenuItem value="low">Low</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>         
                            </div>
                            {/* Shoe shape */}
                            <div className="row my-2">
                                <div className="col">
                                    <FormControl fullWidth>
                                        <InputLabel>Shape</InputLabel>
                                        <Select
                                        label='shape'
                                        value={filters.shoeShape}
                                        onChange={(e) => handleFilterChange('shoeShape', e.target.value)}
                                        >
                                        <MenuItem value="">Any</MenuItem>
                                        <MenuItem value="normal">Normal</MenuItem>
                                        <MenuItem value="foot shaped">Foot shaped</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>         
                            </div>


                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                {/* Brands filter row */}
                <div className="row mt-2">
                            
                            <div className="col">
                                <Accordion expanded={expanded === 'brandpanel'} onChange={handleChange('brandpanel')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="brandpanelbh-content"
                                        id="brandpanelbh-header"
                                    >
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <StorefrontIcon/>
                                            </div>
                                            <div className="col-auto px-1">
                                                Brand
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.brands && filters.brands.length > 0 ? `(${filters.brands.length})` : null }
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup onChange={(e) => handleFilterChange('brands', e.target.value)}>
                                            {allBrands?.map((brand) => (
                                                <FormControlLabel key={`${brand.slug}Checkbox`} value={brand.name} control={filters.brands.includes(brand.name) ? <Checkbox value={brand.title} checked={true} /> : <Checkbox value={brand.title} checked={false} /> } label={brand.name} />
                                            ))}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                </div>

                {/* Price filter row */}
                <div className="row mt-1">
                            <div className="col">
                            <Accordion expanded={expanded === 'budgetpanel'} onChange={handleChange('budgetpanel')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="budgetpanelbh-content"
                                id="budgetpanelbh-header"
                            >
                                <div className="row">
                                        <div className="col-auto">
                                            <AttachMoneyIcon/>
                                        </div>
                                        <div className="col-auto px-1">
                                            Price
                                        </div>

                                    </div>
                            </AccordionSummary>

                            <AccordionDetails>

                            <FormGroup >
                            <FormControl fullWidth>
                                <InputLabel id="high-price-label">Max price</InputLabel>
                                    <Select
                                        labelId="high-price-label"
                                        id="high-price-select"
                                        name="highPrice"
                                        value={filters.price}
                                        label="Highest"
                                        onChange={(e) => handleFilterChange('price', e.target.value)}
                                    >
                                        <MenuItem value={550}>Any</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={150}>150</MenuItem>
                                        <MenuItem value={175}>175</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={225}>225</MenuItem>
                                        <MenuItem value={250}>250</MenuItem>
                                        <MenuItem value={275}>275</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={350}>350</MenuItem>
                                        <MenuItem value={450}>450</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                    </Select>
                            </FormControl>    
                            </FormGroup>
                            </AccordionDetails>
                            </Accordion>
                            </div>
                        </div>
                {/* Offers filter row */}
                <div className="row mt-2">
                            <div className="col">
                                <Accordion expanded={expanded === 'dealspanel'} onChange={handleChange('dealspanel')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="dealspanellbh-content"
                                    id="dealspanellbh-header"
                                >
                                    <div className="row">
                                            <div className="col-auto">
                                                <LocalOfferOutlinedIcon />
                                            </div>
                                            <div className="col-auto px-1">
                                                Offers
                                            </div>

                                        </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <FormControl>
                                        <RadioGroup value={filters.offers}>
                                            <FormControlLabel key={`dealsTrueCheckbox`} control={<Radio value={false} onChange={(e) => handleFilterChange('offers', e.target.value)} />  } label={"Show all shoes"} />
                                            <FormControlLabel key={`dealsFalseCheckbox`} control={<Radio value={true} onChange={(e) => handleFilterChange('offers', e.target.value)} /> }  label={"Has retail offers"} />
                                        </RadioGroup>  
                                    </FormControl>
                                </AccordionDetails>
                                </Accordion>
                            
                            </div>
                        </div> 

                        {/* Match % filter row */}
                        <div className="row mt-1">
                            <div className="col">
                                <Accordion expanded={expanded === 'scorepanel'} onChange={handleChange('scorepanel')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="scorepanelbh-content"
                                    id="scorepanelbh-header"
                                >
                                    <div className="row">
                                            <div className="col-auto">
                                                <RecommendOutlinedIcon/>
                                            </div>
                                            <div className="col-auto px-1">
                                                Match score
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.matchScore != 0 ? '(1)' : null}
                                            </div>

                                        </div>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <div className="row mb-3">
                                        <div className="col">
                                        <FormControl fullWidth>
                                        <InputLabel id="min-score-label">Minimum score</InputLabel>
                                            <Select
                                                defaultValue={filters?.matchScore}
                                                labelId="min-score-label"
                                                id="min-score-select"
                                                name="minScore"
                                                label='Minimum score'
                                                value={filters?.matchScore}
                                                onChange={(e) => handleFilterChange('matchScore', e.target.value)}
                                            >
                                                <MenuItem value={0}>0</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={30}>30</MenuItem>
                                                <MenuItem value={40}>40</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={60}>60</MenuItem>
                                                <MenuItem value={70}>70</MenuItem>
                                                <MenuItem value={80}>80</MenuItem>
                                                <MenuItem value={90}>90</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                            </Select>
                                </FormControl>  
                                        </div>
                                    </div>

                                </AccordionDetails>
                                </Accordion>
                            
                            </div>
                        </div>

                        {/* Current shoes row */}
                        <div className="row mt-1">
                            <div className="col">
                                <Accordion expanded={expanded === 'currentShoespanel'} onChange={handleChange('currentShoespanel')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="currentShoespanellbh-content"
                                    id="currentShoespanellbh-header"
                                >
                                    <div className="row">
                                            <div className="col-auto">
                                                <DoorSlidingOutlinedIcon />
                                            </div>
                                            <div className="col-auto px-1">
                                                Current shoes
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.currentShoes? '(1)' : null}
                                            </div>

                                        </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <FormControl>
                                        <RadioGroup defaultValue={true}>
                                            <FormControlLabel key={`currentShoesTrueCheckbox`} control={<Radio value={true} onClick={(e) => handleFilterChange('currentShoes', e.target.value)}  /> } label={"Recommended"} />
                                            <FormControlLabel key={`currentShoesFalseCheckbox`} control={<Radio value={false} onClick={(e) => handleFilterChange('currentShoes', e.target.value)} /> }  label={"Include all current shoes"} />
                                        </RadioGroup>  
                                    </FormControl>
                                </AccordionDetails>
                                </Accordion>
                            
                            </div>
                        </div>
                    </div> 

            </div>
        </Badge>
    )   
}