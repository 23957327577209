import Card from '@mui/material/Card';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonBase, Checkbox, Chip, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChipComponent from '../../../Chip';
import Divider from '@mui/material/Divider';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpAlt'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import PurchaseShoeModal from '../../Modal/PurchaseShoeModal';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Badge from '@mui/material/Badge';
import PaidIcon from '@mui/icons-material/Paid';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarRate from '@mui/icons-material/StarRate';
import WidthWideIcon from '@mui/icons-material/WidthWide';
import { createEvent } from '../../../../utils/CreateEvent/index.js';

export default function ShoeCard({ recommendationId = null, shoe = null, preferences = null, currentShoe = null, requirements = null, partnerDeals = null, toggleShoe = null, selectedShoes = null, notRec = false }) {

    var [ user ] = useOutletContext()
    var { trackEvent, requestUrl, sessionUser } = useAppContext()

    var [ shoePartnerDeals, setShoePartnerDeals ] = useState([])

    var colorwayIndex = shoe.shoe.colorways?.findIndex((colorway) => 
        colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption))
    )

    if (colorwayIndex === -1) {
        colorwayIndex = 0;
    }

    // Initialize the state for shoeColorway properly
    const [shoeColorway, setShoeColorway] = useState(shoe.shoe.colorways?.[colorwayIndex] || null);

    useEffect(() => {
        if (partnerDeals && partnerDeals.length > 0) {
            const filteredDeals = partnerDeals.filter((deal) =>
                deal.shoe.toString() === shoe.shoe._id.toString() &&
                sessionUser.location ? deal.partner.countries.includes(sessionUser.location?.country) : deal
            );
            setShoePartnerDeals(filteredDeals);
        }
    }, [partnerDeals, sessionUser, shoe.shoe._id]);

    // useEffect to set initial shoe colorway only when component mounts
    useEffect(() => {
        if (shoe.shoe.colorways?.length > 0) {
            const initialColorway = shoe.shoe.colorways[colorwayIndex];
            setShoeColorway(initialColorway);
        }
    }, [shoe.shoe.colorways, colorwayIndex]);

    const navigate = useNavigate();

    const handleClick = (evt, tabIndex) => {
        const url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`;
        navigate(url, {
            state: {
                shoe: shoe,
                preferences: preferences,
                requirements: requirements,
                shoeColorway: shoeColorway,
                tabIndex: tabIndex,
                shoePartnerDeals: shoePartnerDeals,
            },
        });
    };

    const handleFindRetailer = () => {
        trackEvent('Find a retailer', {
            shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`,
        });

        createEvent({
            action: 'search_google_for_retailer',
            category: 'interaction',
            data: {
                shoeId: shoe?.shoe._id,
                colorwayId: shoeColorway?._id,
                recommendationId: recommendationId,
            }
        })

        const url = `https://www.google.com/search?q=buy+${encodeURIComponent(shoe.shoe.brand.name || '')}+${encodeURIComponent(
            (shoe.shoe.model || '').charAt(0).toUpperCase() + shoe.shoe.model?.slice(1) || ''
        )}+${encodeURIComponent(shoe.shoe.version || '')}`;

        axios({
            url: `${requestUrl}/api/users/email/running-tips`,
            method: 'POST',
            withCredentials: true,
        });

        window.open(url, '_blank');
    };

    const createdAtTimestamp = shoe.shoe.createdAt ? new Date(shoe.shoe.createdAt).getTime() : -Infinity;
    const days30AgoTimestamp = new Date(Date.now() - 45 * 24 * 60 * 60 * 1000); // 30 days ago


    // Check if `createdAt` is within the last 180 days
    const isNewModel = createdAtTimestamp >= days30AgoTimestamp.getTime() && createdAtTimestamp <= Date.now();

    // Determine the label based on the version
    const isNewVersion = shoe.shoe.version !== 'v1' && shoe.shoe.version !== '1' && shoe.shoe.version !== '001';
    const label = isNewVersion ? "New version" : "New model";

    const handleColorwayChange = (evt, colorway) => {
        setShoeColorway(colorway)
        createEvent({
            action: 'view_shoe_colorway',
            category: 'interaction',
            data: {
                shoeId: shoe.shoe._id,
                colorwayId: colorway._id,
                page: 'recommendation',
                recommendationId: recommendationId,   
            }
        })
    }



    return (
        <Card 
            key={shoe.shoe_id}
            style={currentShoe ? { border: "2px solid #1F8BF4" }: null}
            className='px-2'
            >
            {/* Match pill */}
             <div key={`${shoe.shoe._id}_match_pil`} className="position-relative p-3 mt-1 mb-1">
             <div className='row justify-content-end'>
                 <div className='col-auto px-1'>
                     <Checkbox 
                     checked={selectedShoes.some(selectedShoe => selectedShoe.shoe._id == shoe.shoe._id)}
                     onChange={(evt) => toggleShoe(evt, shoe)} 
                     />
                 </div>
             </div>    
             {/* Centered Chip */}
             <div className="position-absolute" style={{ top: '22px', left: '50%', transform: 'translateX(-50%)' }}>
                 <Tooltip 
                     arrow 
                     disableFocusListener 
                     enterTouchDelay={0} 
                     leaveTouchDelay={5000}
                     title={
                         <span>
                             {shoe.criteria.sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                 var string = criteria.trait.split(/(?=[A-Z])/);
                                 var finalString = string.join(' ').toLowerCase();
                                 if (requirements[criteria.trait].reasoning.length > 0) {
                                     return (
                                         <div key={`${criteria.trait}TraitRow`} className="row">
                                             <div className="col">
                                                 {criteria.pass === 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {Array.isArray(shoe.shoe[criteria.trait]) ? shoe.shoe[criteria.trait].join(', ') : shoe.shoe[criteria.trait]}
                                             </div>
                                         </div>
                                     );
                                 }
                             })}
                             <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => handleClick(evt, 1)}>Learn more</a>
                         </span>
                     }
                 >
                     <span>
                         <ChipComponent
                             icon={
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                             }
                             label={!notRec ? `${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match` : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'Great profile match' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'Average profile match' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'Average profile match' : 'Poor profile match' 
                                    }
                             color={
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                 ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                             }
                             variant={(shoe.score / shoe.maxScore) < 1.0 ? 'outlined' : null}
                             href={`/dashboard/recommendations/${recommendationId}/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}#matching-information`}
                         />
                     </span>
                 </Tooltip>
             </div>


            </div>

           




            {/* Brand image */}
            <div key={`${shoe.shoe._id}_brandImage=`} className="row">
                <div className="col-9 mx-auto text-center">
                    <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" />
                </div>
            </div>


            {/* Shoe image */}
            <div className="row pt-2">
                <div className="col-12 mx-auto text-center">
                    <a href={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` : `/dashboard/browse/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` }><img  style={{cursor: 'pointer'}} onClick={handleClick} loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${shoeColorway?.colorwayCode}.png`} alt="" /></a>
                </div>
            </div>

            <CardContent className='py-3'>

            
            

            

            <div className="row mt-2 px-2 justify-content-center justify-content-md-start">
    {shoe.shoe.colorways?.sort((a, b) => {
        // Check if colorway has a deal
        const hasDealA = partnerDeals.some(deal => deal.shoeColorway.toString() === a._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;
        const hasDealB = partnerDeals.some(deal => deal.shoeColorway.toString() === b._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;

        // Check if width is available for colorway A and B
        const widthAvailableA = shoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));
        const widthAvailableB = shoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));

        // Sort priority:
        // 1. Has deal in the required width
        // 2. Has width available but no deal
        // 3. Has deal but not in the required width
        // 4. No deal and no width available
        if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
            return hasDealB - hasDealA; // Prioritize deals with width availability
        }
        if (widthAvailableA !== widthAvailableB) {
            return widthAvailableB - widthAvailableA; // Prioritize width availability
        }
        if (hasDealA !== hasDealB) {
            return hasDealB - hasDealA; // Prioritize deals
        }

        return 0; // No change if both deal and width are the same
    })
    .map((colorway, index) => {
        const widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' ? colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)) : true;
        return (
            <div className="col-2 mx-1 my-1" style={{cursor: widthAvailable ? 'pointer' : 'auto'}} key={`${shoe.shoe._id}Colorway${index}`} onClick={(evt) => handleColorwayChange(evt, colorway)}>
                <Tooltip title={widthAvailable ? `${colorway.colorwayName}` : `${colorway.colorwayName} (not available in ${requirements.width.traits.join(' or ')})`}>
                    <div className="row">
                        <div className="col py-1 py-xl-2" style={{border: shoeColorway.colorwayCode === colorway.colorwayCode ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') : '1px solid #D9D9D9', borderRadius: '0.5em', opacity: widthAvailable ? '1' : '0.3'}}>
                            <img className='img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} alt="" />
                        </div>
                    </div>
                </Tooltip>
            </div>
        )
    })}
</div>


            <div className="row mt-3">

            {/* Label (new, best for, most cushioned etc) */}
            {currentShoe ?
                
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label="Owned"
                            />
                    </div>          
                : 
                null
            }
            
            {shoePartnerDeals?.some(deal => deal.shoeColorway.toString() == shoeColorway?._id?.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal )) ?
                <div className="col-auto px-2">
                    <ChipComponent 
                        color='success'
                        size='small'
                        label="Retail offers"
                        icon={<AttachMoneyIcon/>}
                        />
                </div>  
            : null}

            {isNewModel ? (
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label={label}
                        />
                    </div>
            ) : null}

            {shoe?.shoe.shoeShape == 'foot shaped' ? (
                    <div className="col-auto px-2">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label={`Great for wide feet`}
                            icon={<WidthWideIcon/>}
                        />
                    </div>
            ) : null}

            </div>


            <div className="row mt-3">
                <div className="col-auto">
                    <h2 style={{cursor: 'pointer'}} onClick={handleClick}>{shoe.shoe.brand.name} {(shoe.shoe.model).charAt(0).toUpperCase() + shoe.shoe.model.slice(1)} {shoe.shoe.version}</h2>
                </div>
            </div>

            
            <div className="row mt-1">
                <div className="col-auto">
                    {/* Find the first valid deal with specialPrice */}
                    {(() => {
                        const validDeal = shoePartnerDeals?.find(deal => 
                            deal.shoeColorway.toString() === shoeColorway?._id?.toString() &&
                            (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal) &&
                            deal.specialPrice
                        );
                        
                        // Display specialPrice if available, otherwise show MSRP
                        return validDeal ? (
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <h3 className='my-0' style={{color: '#2E7d43'}}>${validDeal.specialPrice.toFixed(0)}</h3>
                                </div>
                                <div className="col-auto px-0">
                                        <h4 className='my-0' style={{textDecoration: 'line-through'}}>${shoe.shoe.msrp}</h4> 
                                </div>
                            </div>
                            
                        ) : (
                            <h4>${shoe.shoe.msrp}</h4>  /* Default to MSRP */
                        );
                    })()}
                </div>
            </div>

            
            {shoe.shoe.averageWebsiteScore && shoe.shoe.averageWebsiteScore !== "" && shoe.shoe.averageWebsiteScore != "NaN" ?
            <div className="row mt-2 align-items-center" style={{cursor: 'pointer'}} onClick={(evt) => handleClick(evt, 2)}>
                <div className="col-auto">
                    <Rating readOnly value={parseFloat(shoe.shoe.averageWebsiteScore)/2} precision={0.01}/>
                </div>
                <div className="col-auto px-0">
                    <p className='my-0'>({shoe.shoe.websiteReviews.length})</p>
                </div>
                
            </div>

            :
            <div className="row mt-1">
                <p><i>pending reviews..</i></p>
            </div> }
            

            

            

            <div className="row px-2 mt-2">
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.cushionSpec}`}
                                size='small'
                                color='success'
                                variant='outlined'
                            />
                </div>
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.cushion} cushion (${shoe.shoe.stackHeight}mm)`}
                                size='small'
                                color='default'
                            />
                </div>
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.weight}g`}
                                size='small'
                                color='default'
                            />
                </div>
                {preferences.shoeSurface == 'trail' || preferences.shoeSurface == 'hybrid' ?
                <div className="col-auto my-1 px-1">
                    <ChipComponent
                                label={`${shoe.shoe.grip} grip`}
                                size='small'
                                color='default'
                            />
                </div>
                : null }
                {shoe.shoe.carbonPlate == 'TRUE' ?
                    <div className="col-auto my-1 px-1">
                        <ChipComponent
                                label={`carbon plate`}
                                size='small'
                                color='info'
                                variant='outlined'
                            />
                    </div>
                : null}

                <div className="col-auto my-1 px-1">
                <ChipComponent
                        label={`${shoeColorway?.widthOptions?.length} width options`}
                        size='small'
                        color='default'
                    />
                </div>


            </div>

            </CardContent>
            <CardActions>
                <div className="col">
                    <div className="row justify-content-center">
                    
                        <div className="col-auto my-1">
                            { partnerDeals?.some(deal => deal.shoeColorway.toString() == shoeColorway?._id?.toString() && (shoe.shoe.shoeShape !== 'foot shaped' ? requirements.width.traits.includes(deal.width) : deal )) ?
                            <Button size="small" endIcon={<ShoppingCartIcon/>} variant="outlined" onClick={handleClick}>Find a retailer</Button>
                            : <Button size="small" endIcon={<ShoppingCartIcon/>} variant="outlined" onClick={handleFindRetailer} target="_blank" >Find a retailer</Button>
                            }   
                            {/* <Button onClick={(evt) => setPurchaseShoeModalOpen(true)} variant='outlined' size="small" endIcon={<ShoppingCartIcon/>}>Buy now</Button> */}
                        </div>
                        <div className="col-auto my-1">
                            <a href={`/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`}><Button onClick={handleClick} variant='outlined' size="small" endIcon={<ArrowRightAltIcon/>}>View Details</Button></a>
                        </div>
                        
                    </div>
                </div>
                
                
            </CardActions>
             {/* <PurchaseShoeModal openState={purchaseShoeModalOpen} setOpenState={setPurchaseShoeModalOpen} shoe={shoe} colorway={shoeColorway} requirements={requirements} /> */}
        </Card>
       
    )
}