import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import RecommendIcon from '@mui/icons-material/Recommend';
import SearchIcon from '@mui/icons-material/Search';
import LockIcon from '@mui/icons-material/Lock';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useAppContext } from '../../../utils/ContextProvider.jsx';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import CampaignIcon from '@mui/icons-material/Campaign';

const drawerWidth = 240;

const SideNavBarMobile = ( { open = null, setOpen = null }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current URL

  var { setProcessing, requestUrl, setIsAlert, setProcessing, setSessionUser, isProcessing } = useAppContext()

  var navigateUrl = ( url) => {
      setOpen(false)
      navigate(url)
  }

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/dashboard', function: () => navigateUrl( '/dashboard') },
    { text: 'Recommendations', icon: <RecommendIcon />, path: '/dashboard/recommendations', function: (evt) => navigateUrl( '/dashboard/recommendations') },
    { text: 'Browse', icon: <SearchIcon />, path: '/dashboard/browse', function: (evt) => navigateUrl( '/dashboard/browse') },
    { text: 'Shoe Locker', icon: <LockIcon />, path: '/dashboard/shoe-locker', function: (evt) => navigateUrl( '/dashboard/shoe-locker') },
    { text: 'Profile', icon: <PersonIcon />, path: '/dashboard/profile', function: (evt) => navigateUrl( '/dashboard/profile') },
  ];

  const bottomItems = [
    { text: 'Updates', icon: <CampaignIcon />, path: '/support', function: () => window.open('https://fittir.changelogfy.com/changelog/en')},
    { text: 'Support', icon: <SupportAgentIcon />, path: '/support', function: () => window.location.href = 'mailto:hi@fittir.io?subject=Support Request' },
    { text: 'Log Out', icon: <LogoutIcon />, path: '/logout', function: () => logOutUser() },
  ];

  const logOutUser = (evt) => {
    setProcessing(true)
    const url = `${requestUrl}/api/users/logout`
    axios({
        url: url,
        method: "POST",
        withCredentials: true
    })
    .then((res) => {
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("userInfo");
        mixpanel.reset()
        setIsAlert(isAlert => {
          return {
              ...isAlert,
              success: `Logged out, see you next time 👋 `
          }
        })
        setProcessing(false)
        setSessionUser({})
        navigate("/")
    })
    .catch((err) => {
      Sentry.captureException(err)
      var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
      setIsAlert(isAlert => {
        return {
            ...isAlert,
            error: errorMessage
        }
      })
      setProcessing(false)
    })
}

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={(evt) => setOpen(false)}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div className="row my-3">
        <div className="col-8 mx-4">
        <a href="/"><img className='img img-fluid' src="/logos/logo-primary.svg" alt="Logo" /></a>
        </div>
      </div>

      {/* Flex container with space between top items and bottom items */}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={item.function}
                selected={
                  item.path === '/dashboard'
                    ? location.pathname === item.path
                    : location.pathname.startsWith(item.path)
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/* Spacer div to push the bottom items to the bottom */}
        <div style={{ flexGrow: 1 }}></div>

        {/* Bottom items (Support and Log Out) */}
        <List>
          {bottomItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={item.function}
                selected={location.pathname.startsWith(item.path)}
                disabled={item.text == 'Log Out' ? (isProcessing ? true : null ) : null}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default SideNavBarMobile;

