import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index.jsx"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"

export default function AllPartnerMappingsPage () {

    var [ unprocessedMappings, setUnprocessedMappings ] = useState()
    
    var [ allShoes, setAllShoes ] = useState([])

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading } = useAppContext()

    var navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [partnerDealsRes, shoesRes] = await Promise.all([
                    axios({
                        url: `${requestUrl}/api/partnerDeals?status=unprocessed`,
                        method: "GET",
                        withCredentials: true
                    }),
                    axios({
                        url: `${requestUrl}/api/shoes/`,
                        method: "GET",
                        withCredentials: true
                    })
                ]);
    
                // Update state with data from both API calls
                setUnprocessedMappings(partnerDealsRes.data);
                setAllShoes(shoesRes.data.allShoes);
            } catch (err) {
                const errorMessage = err.response?.data.err.message || "Whoops, something went wrong";
                setIsAlert(pageData => ({
                    ...pageData,
                    error: errorMessage
                }));
            }
        };
    
        fetchData();
    }, [requestUrl])

    const handleClick = (evt, mapping) => {
        navigate(`/admin/partners/mappings/${mapping._id}`, {state: {mapping: mapping, unprocessedMappings: unprocessedMappings, allShoes: allShoes }})
    }

    return (
        <div>
            <DashboardHeader
                    heading={`Shoe deals to map to shoes`}
                    subHeading={`Click on each item to view a new deal for a shoe. Map the deal to a shoe and shoe colorway for it to show in Fittir.`}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
            <div className="container-md">
                <div>
                    <p>{unprocessedMappings?.length} total unmapped deals</p>
                </div>
                {unprocessedMappings?.map((mapping) => { 
                    return (
                        <div className="row my-3">
                            <div className="col py-3 px-4" style={{backgroundColor: 'white'}}>
                                <div className="row">
                                    <div className="col-2">
                                        <img className="img img-fluid" src={`/logos/partners/${mapping.partner.slug}.svg`} alt="" />
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col">
                                                <h2>{mapping.urlKey} ({mapping.price})</h2>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-8" style={{wordBreak: 'break-all'}}>
                                                <p className="my-0">{mapping.sku}</p>
                                            </div>
                                            <div className="col-auto">
                                                <Button onClick={(evt) => handleClick(evt, mapping)}>Process Mapping</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    )
                })}

            </div>
        </div>
        </div>
        
    )
}