import { useEffect, useState } from "react"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import { Skeleton, Tooltip, Button } from "@mui/material"
import axios from "axios"
import { useAppContext } from "../../../../utils/ContextProvider.jsx"
import { useOutletContext } from "react-router-dom"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GeneralTooltip from "../../../../components/ToolTip/index.jsx"

export default function AIComparisonPanel ({ shoeList = null }) {

    var [ isFetching, setIsFetching ] = useState(false)

    var [ response, setResponse ] = useState()

    var { isAlert, setIsAlert, requestUrl, sessionUser, setUpgradeModalOpen } = useAppContext()

    useEffect(()=> {
            setIsFetching(true)
            // API call to fetch response
            axios({
                url: `${requestUrl}/api/integrations/open_ai/comparison`,
                method: 'POST',
                data: {
                    shoeList: shoeList
                },
                withCredentials: true
            })
            .then((res)=> {
                setResponse(res.data.comparisonSummary)
                setIsFetching(false)
            })
            .catch((err) => {
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    }
                })
                setIsFetching(false)
            })
    }, [shoeList, sessionUser] )

        if (isFetching) {
            return (
                <div className="col ask-ai-tile py-3 px-3">
                    <div className="row">
                        <div className="col-auto">
                            <AutoAwesomeIcon />
                        </div>
                        <div className="col">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h4>Summary:</h4>
                                </div>
                                <div className="col-auto">
                                    <GeneralTooltip title={'Powered by Fittir AI, we fetch information from wear-testers and high quality reviews to simplify the process of research and comparison.'} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
            <div className="col ask-ai-tile py-3 px-3">
                <div className="row ">
                    <div className="col-auto">
                        <AutoAwesomeIcon />
                    </div>
                    <div className="col">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h4>Summary:</h4>
                            </div>
                            <div className="col-auto">
                                <GeneralTooltip  title={'Powered by Fittir AI, we fetch information from wear-testers and high quality reviews to simplify the process of research and comparison.'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>{response}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    
}