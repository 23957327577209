import { useEffect, useState } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios"
import Box from '@mui/material/Box';
import { Button, Chip } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import InfoIcon from '@mui/icons-material/Info';
import ReviewCard from "../../../../components/Dashboard/Cards/ReviewCard";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as Sentry from "@sentry/react";

export default function PreviousModelSection( { shoe = null, sendToCompare = null, setShoeColorway = null, preferences= null, requirements= null, notRec = null}) {

    const { isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    const { recommendationId } = useParams()

    const navigate = useNavigate()
    
    var [isLoading, setIsLoading] = useState(false)

    var [previousModel, setPreviousModel] = useState([])

    useEffect(()=> {
        if (shoe) {
            setIsLoading(true)
            var url = `${requestUrl}/api/recommendations/${recommendationId}/shoes/${shoe.shoe._id.toString()}/previous-models`
            axios({
                url: url,
                withCredentials: true
            }).then((res) => {
                var previousModels = res.data.previousModels
                setPreviousModel(previousModels)
                setIsLoading(false)
            }
            ).catch((err)=> {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setIsLoading(false)
            })
        }
    }, [])

    const handleClick = (evt, shoe) => {
        var url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`
        setShoeColorway(shoe.shoe.colorways[0])
        navigate(url, {state: {shoe: shoe, requirements: requirements, preferences: preferences}} )
        
    }

    const handleCompareClick = (evt) => {
        sendToCompare(evt, [shoe, previousModel[0]])
    }


    if (isLoading) {
        return (
            <div className="my-4">
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                
            </div>
        )
    } else {
        return (
            <div className="row mt-3">
                {previousModel?.map((shoe)=> {
                    return (
                        <div className="col text-center grey-outline py-2">
                            <div className="row align-items-center">
                                <div className="col-auto mx-auto">                                 
                                    <Chip size="small" variant="outlined" color="info" label={'Older model'} icon={<AccessTimeIcon />} />
                                </div>
                                <div className="col-12 col-sm text-center text-sm-start mt-2">
                                    <h4 className="my-0">{shoe.shoe.brand.name} {shoe.shoe.model} {shoe.shoe.version} (${shoe.shoe.msrp})</h4>
                                </div>
                                <div className="col-12 col-sm-4 mt-2">
                                    <img className="img img-fluid" src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${shoe.shoe.colorways[0].colorwayCode}.png`} alt="" />
                                </div>

                                <div className="col-12 col-sm mt-2">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <a href={!notRec ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}` : `/dashboard/browse/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`} onClick={(evt) => handleClick(evt, shoe)} style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>View <ArrowRightAltIcon/></a>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <a onClick={(evt) => handleCompareClick(evt)} style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>Compare <ArrowRightAltIcon/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}