import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ChipComponent from '../../../Chip';
import Divider from '@mui/material/Divider';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import CheckIcon from '@mui/icons-material/Check';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Tooltip from '@mui/material/Tooltip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as Sentry from "@sentry/react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Alert from '@mui/material/Alert';
import Select from "@mui/material/Select"
import { preferencesOptions } from '../../../../utils/PreferencesOptions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { shoeBrands } from '../../../../utils/ShoeBrands';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function RecommendationCard({ recommendation = null, allBrands = null }) {


    var [user, userShoes] = useOutletContext()

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
        
    };

    const handleCloseMenu = (evt) => {
        setAnchorEl(null);
    };

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, trackEvent, requestUrl, isProcessing, setProcessing, upgradeModalOpen, setUpgradeModalOpen } = useAppContext()

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openUpdateModal, setOpenUpdateModal] = useState()
    
    const handleUpdateModalOpen = () => {
        setOpenUpdateModal(true)
    }

    const handleUpdateModalClose = () => {
        setOpenUpdateModal(false)
    }

    const navigate = useNavigate()

    const location = useLocation()

    const [shoes, setShoes] = useState(recommendation.shoes)

    var [preferences, setPreferences] = useState(recommendation.preferences)

    var colorwayIndex = shoes[0]?.shoe.colorways?.indexOf(shoes[0].shoe.colorways.filter((colorway) => colorway?.widthOptions?.some(widthOption => recommendation.requirements.width.traits.includes(widthOption)))[0]) 

    if (colorwayIndex == -1) {
        colorwayIndex = 0
    }

    useEffect(() => {
        if (recommendation.preferences.shoeBrands.length > 0) {
            setShoes(
                shoes.filter((shoe) => {
                    return recommendation.preferences.shoeBrands.includes(shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= recommendation.preferences.shoeBudget.highPrice && shoe.shoe.latestModel === "TRUE" && (userShoes?.length > 0 ? (userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]?.shoeReviewId.rating) > 3 || !userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0] : (shoe) )
                }).sort((a,b) => {
                    return b.score - a.score ||  parseFloat(b.shoe.averageWebsiteScore).toFixed(1)  - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || b.shoe.websiteReviews.length - a.shoe.websiteReviews.length
                })
            )
        } else {
            setShoes(
                shoes.filter((shoe) => {
                    return parseInt(shoe.shoe.msrp) <= recommendation.preferences.shoeBudget.highPrice && shoe.shoe.latestModel === "TRUE" && (userShoes?.length > 0 ? (userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]?.shoeReviewId.rating) > 3 || !userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0] : (shoe))
                }).sort((a,b) => {
                    return b.score - a.score || parseFloat(b.shoe.averageWebsiteScore).toFixed(1)  - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || b.shoe.websiteReviews.length - a.shoe.websiteReviews.length
                })
            )
        }
    }, [])

    const handleDelete = (evt) => {
        setProcessing(true)
        axios({
            url: `${requestUrl}/api/recommendations/${recommendation._id}`,
            method: "DELETE",
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Recommendation deleted')
            setProcessing(false)
            setOpen(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Recommendation deleted!`
                }
            })
            window.location.reload()
            
        })
        .catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }

    const handleChange = (evt) => {
        var target = evt.target.name
        var value = evt.target.value
        trackEvent('Update preferences field', {profileField: evt.target.name})
        setPreferences(currData => {
            return {
                ...currData,
                [target]: value

            }
        })
   }

   const handleBrandChange = (evt, value) => {
    if (value) {
        var brands = []
        for (let i = 0; i < value.length; i++) {
            brands.push(value[i])
        }
        setPreferences(currData => {
            return {
                ...currData,
                shoeBrands: brands

            }
        })

    }
   }

   const handlePriceChange = (evt) => {
        var target = evt.target.name
        var value = evt.target.value
        setPreferences(currData => {
            return {
                ...currData,
                shoeBudget: {
                    ...currData.shoeBudget,
                    [target]: value
                }
            }
        })
   }

    const handleUpdateRecommendation = (evt) => {
        setProcessing(true)
        axios({
            url: `${requestUrl}/api/recommendations/${recommendation._id}`,
            method: "PUT",
            data: preferences,
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Recommendation Updated')
            var alert = res.data.alert
            setProcessing(false)
            setOpen(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Recommendation Updated! ${alert? alert : ''}`
                }
            })
            window.location.reload()
        })
        .catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }

    const handleNav = (evt) => {
        navigate(`/dashboard/recommendations/${recommendation._id}`)
    }

    const handleClick = (evt) => {
        navigate(`/dashboard/recommendations/${recommendation._id}`, { state: { shoe: shoes[0], preferences: recommendation.preferences, requirements: recommendation.requirements, shoeColorway: shoes[0].shoe.colorways[colorwayIndex] } })
    }

    return (
        <div style={{height: '100%'}}>
            <Card key={recommendation._id}>
                {shoes[0]? 
                <CardContent className='px-0'>
                    {/* Header (title + action) */}
                    <div className="row px-3 pb-3 align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <img className='img img-fluid' src={`/icons/shoeSurfaces/${recommendation.preferences?.shoeSurface}.svg`} alt="" />
                                        </div>
                                        <div className="col-auto px-0">
                                            <img className='img img-fluid' src={`/icons/shoeTypes/${recommendation.preferences?.shoeUsage}.svg`}  alt="" />
                                        </div>
                                        <div className="col text-start">
                                            <h3 className='my-0'>{((recommendation.preferences?.shoeUsage).charAt(0).toUpperCase() + recommendation.preferences?.shoeUsage.slice(1)).replace('_', ' ') },  {recommendation.preferences?.shoeSurface} running {recommendation.preferences.trailSurface ? `(${recommendation.preferences?.trailSurface}${recommendation.preferences?.conditions? `, ${recommendation.preferences.conditions}` : ""})` : (recommendation.preferences.speedType ? `(${recommendation.preferences?.speedType})` : null )} </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Dropdown button */}
                        <div className="col-auto">
                            <IconButton
                                id="basic-button"
                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                onClick={handleClickMenu}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={(evt) => setOpenUpdateModal(true)}><SyncAltIcon />  Update</MenuItem>
                                <MenuItem onClick={(evt) => setOpen(true)}><DeleteIcon/> Delete</MenuItem>
                                
                            </Menu>
                        </div>
          
                    </div>

                    <CardActionArea 
                        onClick={handleClick}
                        >

                    <div className="row mt-4 justify-content-center">
                        <div className="col-auto mt-2">
                            <ChipComponent
                                icon={<StarOutlineIcon />}
                                size={'large'}
                                label={shoes[0] && (shoes[0]?.score / shoes[0]?.maxScore) > 0.95 ?  `Recommended` : `Great option`}
                                color={'success'}
                            />
                        </div>
                        { (shoes[0] && (shoes[0]?.score / shoes[0]?.maxScore) < 1.00)  && (recommendation.preferences.shoeBrands.length > 0 || recommendation.preferences.shoeBudget.highPrice !== 550) ?
                        <div className="col-auto mt-2">
                            <ChipComponent
                                icon={<InfoOutlinedIcon />}
                                size={'large'}
                                label={`Better suited options outside brand preferences`}
                                color={'default'}
                            />
                        </div>
                        : null}
                    </div>
                    
                    {/* Brand image */}
                    <div className="row">
                        <div className="col-8 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoes[0].shoe.brand.slug}.png`} alt="" />
                        </div>
                    </div>


                    {/* Shoe image */}
                    <div className="row pt-2">
                        <div className="col-10 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoes[0].shoe.brand.slug}_${shoes[0].shoe.modelSlug}_${shoes[0].shoe.version}_${shoes[0].shoe.colorways[colorwayIndex]?.colorwayCode}.png`} alt="" />
                        </div>
                    </div>

                    

                    {/* Shoe name */}
                    <div className="row pt-4">
                        <div className="col--auto mx-auto text-center px-5">
                            <h1>{shoes[0].shoe.brand.name} {shoes[0].shoe.model} {shoes[0].shoe.version}</h1>
                        </div>
                    </div>

                    {/* Match pill */}
                    <div className="row py-3 px-4 justify-content-center">
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<ThumbUpOffAltIcon />}
                                variant={'outlined'}
                                label={`${((shoes[0].score / shoes[0].maxScore) * 100).toFixed(0)} % match for your requirements`}
                                color={'default'}
                            />
                        </div>
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<PersonOutlineIcon />}
                                label={`Highest rated in category (${parseFloat(shoes[0].shoe.averageWebsiteScore).toFixed(2)}/10)`}
                                color={'default'}
                                variant={'outlined'}
                            />
                        </div>
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<CheckIcon />}
                                label={`Fits your preferences`}
                                color={'default'}
                                variant={'outlined'}
                            />
                        </div>
                    </div>
                    

                    </CardActionArea>

                <Divider variant="middle" />
                </CardContent>
                : 
                <CardContent>
                    <div className="row px-3 pb-3 align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <img className='img img-fluid' src={`/icons/shoeSurfaces/${recommendation.preferences?.shoeSurface}.svg`} alt="" />
                                        </div>
                                        <div className="col-auto px-0">
                                            <img className='img img-fluid' src={`/icons/shoeTypes/${recommendation.preferences?.shoeUsage}.svg`}  alt="" />
                                        </div>
                                        <div className="col text-start">
                                            <h3 className='my-0'>{(recommendation.preferences?.shoeUsage).charAt(0).toUpperCase() + recommendation.preferences?.shoeUsage.slice(1) }, {recommendation.preferences?.shoeSurface} running </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <Tooltip title="Delete recommendation">
                                <IconButton 
                                    aria-label="deleteRecommendation"
                                    onClick={handleClickOpen}
                                    >
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col text-center">
                            <p>We couldn't recommend a shoe that fits with your preferences.</p>
                            <p>Try expanding your preferences by viewing all options below</p>
                        </div>
                    </div>
                    
                </CardContent>
                
                }
            <CardActions>
                <div className="col-auto mx-auto text-center mb-3">
                    <Button 
                        variant='contained'
                        onClick={handleNav}
                        endIcon={<ReadMoreIcon />}
                    >
                    View all options
                    </Button>
                </div>
                
            </CardActions>
        </Card>




        <React.Fragment>
                <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        maxWidth='sm'
                        className='px-4'
                    >
                    <div className="row mx-1 my-2">
                        <div className="col px-3 my-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <h3 className="my-0">Are you sure you like to delete this recommendation?</h3>
                                </div>
                                <div className="col text-end d-none d-md-inline">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <p>Once deleted, you will have to create another recommendation to recover it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogActions>
                        <div className="col-auto">
                            <Button autoFocus onClick={handleClose}>
                                Cancel
                            </Button>
                        </div>
                        
                        <div className="col-auto px-2 my-2">
                            <Button 
                                autoFocus    
                                variant='contained' 
                                color='error'   
                                onClick={handleDelete}
                                disabled={isProcessing == true ? true : false}
                                endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                
                                >
                                Confirm
                            </Button>
                        </div>
                        
                    </DialogActions>
                </BootstrapDialog>
        </React.Fragment>
        <React.Fragment>
                <BootstrapDialog
                        onClose={handleUpdateModalClose}
                        aria-labelledby="customized-dialog-title"
                        open={openUpdateModal}
                        maxWidth='sm'
                        className='px-4'
                    >
                    <div className="row mx-1 my-2">
                        <div className="col px-3 my-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <h3 className="my-0">Update your recommendation</h3>
                                </div>
                                <div className="col text-end d-none d-md-inline">
                                    <IconButton onClick={handleUpdateModalClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            {/* Shoe surface and usage dropdowns */}
                            <div className="row mt-3 justify-content-center">
                                    <div className="col-12 col-md-6 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Surface</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={preferences.shoeSurface}
                                                    onChange={handleChange}
                                                    disabled
                                                    name="shoeSurface"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {preferencesOptions?.shoeSurface.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Usage</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={preferences.shoeUsage}
                                                    onChange={handleChange}
                                                    disabled
                                                    name="shoeUsage"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {preferencesOptions?.shoeUsage.map((option, index) => 
                                                        <MenuItem key={option.value} name={option.name} value={option.value}>{option.value}</MenuItem>
                                                    )}
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                            </div>
                            {/* Brands dropdown */}
                            <div className="row mt-3">
                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Brand</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                                                        
                                            <div className="col">
                                                <Autocomplete
                                                    key={`${preferences.shoeBrands.length}DropdownUpdateRecommendation`}
                                                    id="country-select-demo"
                                                    options={shoeBrands}
                                                    multiple
                                                    value={preferences.shoeBrands}
                                                    name="shoeBrands"
                                                    autoHighlight
                                                    onChange={(evt, value) => handleBrandChange(evt, value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        {...params}
                                                        label={"Select a brand"}
                                                        autoComplete="false"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'off', // disable autocomplete and autofill
                                                        }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div>
                            {/* Budget dropdown */}
                            <div className="row mt-3 justify-content-center">
                                    <div className="col-12 col-md-6 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Budget (low)</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={preferences.shoeBudget.lowPrice}
                                                    onChange={handlePriceChange}
                                                    name="lowPrice"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                        <MenuItem value={0}>Any</MenuItem>
                                                        <MenuItem value={100}>100</MenuItem>
                                                        <MenuItem value={150}>150</MenuItem>
                                                        <MenuItem value={175}>175</MenuItem>
                                                        <MenuItem value={200}>200</MenuItem>
                                                        <MenuItem value={225}>225</MenuItem>
                                                        <MenuItem value={250}>250</MenuItem>
                                                        <MenuItem value={275}>275</MenuItem>
                                                        <MenuItem value={300}>300</MenuItem>
                                                        <MenuItem value={350}>350</MenuItem>
                                                        <MenuItem value={450}>450</MenuItem>
                                                        <MenuItem value={500}>500</MenuItem>
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-2">
                                        <div className="row">
                                            <div className="col">
                                                <h4>Budget (high)</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <Select
                                                    
                                                    fullWidth
                                                    id="current-shoe-select"
                                                    value={preferences.shoeBudget.highPrice}
                                                    onChange={handlePriceChange}
                                                    name="highPrice"
                                                    MenuProps={{
                                                        PaperProps: {
                                                        style: {
                                                            maxHeight: '50vh',
                                                        },
                                                        },
                                                    }}
                                                    >
                                                        <MenuItem value={550}>Any</MenuItem>
                                                        <MenuItem value={100}>100</MenuItem>
                                                        <MenuItem value={150}>150</MenuItem>
                                                        <MenuItem value={175}>175</MenuItem>
                                                        <MenuItem value={200}>200</MenuItem>
                                                        <MenuItem value={225}>225</MenuItem>
                                                        <MenuItem value={250}>250</MenuItem>
                                                        <MenuItem value={275}>275</MenuItem>
                                                        <MenuItem value={300}>300</MenuItem>
                                                        <MenuItem value={350}>350</MenuItem>
                                                        <MenuItem value={450}>450</MenuItem>
                                                        <MenuItem value={500}>500</MenuItem>
                                                    </Select>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                            </div>
                            {/* Recommendation specific dropdowns */}
                            <div className="row justify-content-start mt-3">
                                {preferences?.speedType?
                                <div className="col-12 col-md-6 mt-2">
                                <div className="row">
                                    <div className="col">
                                        <h4>Speed type</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                        <Select
                                            
                                            fullWidth
                                            id="current-shoe-select"
                                            value={preferences?.speedType? preferences.speedType : null}
                                            onChange={handleChange}
                                            name="speedType"
                                            MenuProps={{
                                                PaperProps: {
                                                style: {
                                                    maxHeight: '50vh',
                                                },
                                                },
                                            }}
                                            >
                                            {preferencesOptions?.speedType.map((option, index) => 
                                                <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                            )}
                                            </Select>
                                        </div>
                                    </div> 
                                </div>
                                    </div>
                                : null}
                                {preferences?.rockPlate == 'true' || preferences?.rockPlate == 'false' ?
                                <div className="col-12 col-md-6 mt-2">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Rocky surfaces?</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <Select
                                                
                                                fullWidth
                                                id="current-shoe-select"
                                                value={preferences?.rockPlate == true || preferences?.rockPlate == false? preferences.rockPlate : null}
                                                onChange={handleChange}
                                                name="rockPlate"
                                                MenuProps={{
                                                    PaperProps: {
                                                    style: {
                                                        maxHeight: '50vh',
                                                    },
                                                    },
                                                }}
                                                >
                                                {preferencesOptions?.rockPlate.map((option, index) => 
                                                    <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                )}
                                                </Select>
                                            </div>
                                        </div> 
                                    </div>
                                    </div>
                                : null}
                                {preferences?.conditions?
                                <div className="col-12 col-md-6 mt-2">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Wet conditions?</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <Select
                                                
                                                fullWidth
                                                id="current-shoe-select"
                                                value={preferences?.conditions? preferences.conditions : null}
                                                onChange={handleChange}
                                                name="conditions"
                                                MenuProps={{
                                                    PaperProps: {
                                                    style: {
                                                        maxHeight: '50vh',
                                                    },
                                                    },
                                                }}
                                                >
                                                {preferencesOptions?.conditions.map((option, index) => 
                                                    <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                )}
                                                </Select>
                                            </div>
                                        </div> 
                                    </div>
                                    </div>
                                : null}
                                {preferences?.cushionType?
                                <div className="col-12 col-md-6 mt-2">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Cushion Type</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <Select
                                                
                                                fullWidth
                                                id="current-shoe-select"
                                                value={preferences?.cushionType? preferences.cushionType : null}
                                                onChange={handleChange}
                                                name="cushionType"
                                                MenuProps={{
                                                    PaperProps: {
                                                    style: {
                                                        maxHeight: '50vh',
                                                    },
                                                    },
                                                }}
                                                >
                                                {preferencesOptions?.cushionType.map((option, index) => 
                                                    <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                )}
                                                </Select>
                                            </div>
                                        </div> 
                                    </div>
                                    </div>
                                : null}
                                {preferences?.trailSurface?
                                <div className="col-12 col-md-6 mt-2">
                                    <div className="row">
                                        <div className="col">
                                            <h4>Trail technicality</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <Select
                                                
                                                fullWidth
                                                id="current-shoe-select"
                                                value={preferences?.trailSurface}
                                                onChange={handleChange}
                                                name="trailSurface"
                                                MenuProps={{
                                                    PaperProps: {
                                                    style: {
                                                        maxHeight: '50vh',
                                                    },
                                                    },
                                                }}
                                                >
                                                {preferencesOptions?.trailSurface.map((option, index) => 
                                                    <MenuItem key={option.value} name={option.name} value={option.value}>{option.title}</MenuItem>
                                                )}
                                                </Select>
                                            </div>
                                        </div> 
                                    </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <DialogActions>                    
                        <div className="col-auto px-2 my-2">
                            <Button 
                                autoFocus    
                                variant='contained' 
                                color='primary'   
                                onClick={handleUpdateRecommendation}
                                disabled={isProcessing == true ? true : false}
                                endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                >
                                Update
                            </Button>
                        </div>
                        
                    </DialogActions>
                </BootstrapDialog>
        </React.Fragment>
        </div>
        

        
    )
}