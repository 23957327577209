import { useEffect, useState } from "react"
import axios from "axios"
import Skeleton from '@mui/material/Skeleton';
import ReviewCard from "../../../../components/Dashboard/Cards/ReviewCard";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import * as Sentry from "@sentry/react";
import PartnerDealCard from "./PartnerDealCard";
import { createEvent } from "../../../../utils/CreateEvent/index.js";

export default function PartnerDealsSection ({ user = null, shoe = null, colorway = null, setColorway = null, shoeId = null, colorwayId = null, partnerDeals = null, recommendationId = null  }) {


    var [partnerDeals, setPartnerDeals] = useState(partnerDeals)

    var { isLoading, setIsLoading, requestUrl, setIsAlert, isAlert, trackEvent } = useAppContext()

    var [isLoading, setIsLoading] = useState(false)

    useEffect(()=> {
        setPartnerDeals(partnerDeals)
    }, [])

    const handleFindRetailer = (evt) => {
        // Track the event
        trackEvent('Find a retailer', {shoeName: `${shoe.brand.name} ${shoe.model} ${shoe.version}`})

        createEvent({
            action: 'search_google_for_retailer',
            category: 'interaction',
            data: {
                shoeId: shoeId,
                colorwayId: colorwayId,
                recommendationId: recommendationId,
            }
        })
        // Navigate the user
        var url = `https://www.google.com/search?q=buy+${encodeURIComponent(shoe.brand.name || '')}+${encodeURIComponent((shoe.model || '').charAt(0).toUpperCase() + shoe.model?.slice(1) || '')}+${encodeURIComponent(shoe.version || '')}+${encodeURIComponent(
                    user?.runningProfile.footWidth === 'wide' 
                    ? (user?.runningProfile.gender === 'male' ? '2E+width' : 'D+width')
                    : (user?.runningProfile.footWidth === 'extra-wide' 
                        ? (user?.runningProfile.gender === 'male' ? '4E+width' : '2E+width') 
                        : '')
                )}+${encodeURIComponent(user?.runningProfile.gender || '')}+${encodeURIComponent(colorway?.colorwayName || '')}`;

        // Que a purchase tips email
        axios({
            url: `${requestUrl}/api/users/email/running-tips`,
            method: "POST",
            withCredentials: true
        })

        window.open(url, "_blank");

    }



    if (isLoading) {
        return (
            <div className="col mx-auto">
                <div className="row mt-3">
                    <div className="col">
                        <h2>Where to buy &#42;</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                
            </div>
        )
    } else {
        return (
            <div className="col mx-auto">
                <div className="row mt-3">
                    <div className="col">
                        <h2>Where to buy &#42;</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div key={`${partnerDeals?.length}${colorway?._id}`} className="col-10 mx-auto px-0">
                        {partnerDeals?.length > 0 ? partnerDeals?.filter((deal) => deal.shoeColorway == colorway._id).map((deal, index) => {
                            return (
                                <PartnerDealCard partnerDeal={deal} shoe={shoe} colorway={colorway} recommendationId={recommendationId}/>
                            )
                        }) 
                        : 
                        <div className="row">
                            <div className="col text-center">
                            <p><i>We couldn't find any stock with our partners. Try <span style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}><a onClick={handleFindRetailer}>searching google</a></span> instead.</i></p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                {partnerDeals?.length > 0 ?
                <div className="row mt-3">
                    <div className="col text-center">
                        <p><i>*Whenever you purchase a shoe with our partners, we get paid a percentage of the shoe price. This has no effect on the price you pay and helps <span style={{textDecoration: 'underline'}}>support us.</span></i></p>
                    </div>
                </div>
                : null
                }
                
            </div>
            
        )
    }
}